import { IonAlert } from "@ionic/react";
import { forwardRef, useImperativeHandle, useState } from "react";

export interface IWarningAlert {
  open: (data: any, header: string, message: string) => void;
  close: () => void;
}

const WarningAlert = forwardRef<
  IWarningAlert,
  {
    onOkClicked: (data: any) => void;
    onCancelClicked: (data: any) => void;
  }
>((props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<{ data: any, header: string; message: string }>({
    data: {},
    header: "Alert",
    message: "Are you sure want to do this?",
  });

  useImperativeHandle(ref, () => ({
    open: (data: any, header: string, message: string) => {
      setValue({ data, header, message });
      setOpen(true);
    },
    close: () => setOpen(false),
  }));

  return (
    <IonAlert
      message={value.message}
      header={value.header}
      isOpen={open}
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            setOpen(false);
            props.onCancelClicked(value);
          },
        },
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            setOpen(false);
            props.onOkClicked(value);
          },
        },
      ]}
      onDidDismiss={({ detail }) => {
        setOpen(false);
        props.onCancelClicked(value);
      }}
    ></IonAlert>
  );
});

export default WarningAlert;
