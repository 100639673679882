import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { BatchAttrs } from "@com.xcodeclazz/batch";

export const batchState = createSlice({
  name: "batchState",
  initialState: {
    batches: [] as BatchAttrs[],
  },
  reducers: {
    saveBatches: (state, action) => {
      state.batches = action.payload.batches;
    },
    addBatch: (state, action) => {
      state.batches.unshift(action.payload);
    },
    deleteBatch: (state, action) => {
      state.batches = state.batches.filter(e => e.id != action.payload.batch);
    },
    updateBatch: (state, action) => {
      const batchPos = state.batches.findIndex(e => e.id === action.payload.batch);
      if (batchPos != -1) state.batches[batchPos] = action.payload.data;
    },
    updateLiveStatus: (state, action) => {
      const batchPos = state.batches.findIndex(e => e.id === action.payload.batch);
      if (batchPos != -1) state.batches[batchPos].isLive = action.payload.isLive;
    },
    clearBatchesState: (state) => {
      state.batches = []
    }
  },
});

// Action creators are generated for each case reducer function
export const { addBatch, saveBatches, updateLiveStatus, updateBatch, deleteBatch, clearBatchesState } = batchState.actions;

export default batchState.reducer;
