import { BatchPayloadJoi_UpdateBatch, BatchPayload_UpdateBatch, BatchResponse_ShowBatch } from "@com.xcodeclazz/batch";
import { errorToast, getShowBatch, patchUpdateBatch } from "../apis";
import { updateBatch } from "../redux/reducers/batchState";
import { useHistory, useLocation } from "react-router";
import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import { isAdmin } from "../utils/common/helper";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Joi from "joi";
import {
  IonCol,
  IonRow,
  IonPage,
  IonGrid,
  IonInput,
  IonButton,
  IonContent,
} from "@ionic/react";

const UpdateBatch: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const daysRef = React.createRef<HTMLIonInputElement>();
  const nameRef = React.createRef<HTMLIonInputElement>();
  const mentorRef = React.createRef<HTMLIonInputElement>();
  const purposeRef = React.createRef<HTMLIonInputElement>();
  const usernameRef = React.createRef<HTMLIonInputElement>();
  const passwordRef = React.createRef<HTMLIonInputElement>();
  const rtmpPathRef = React.createRef<HTMLIonInputElement>();
  const meetPathRef = React.createRef<HTMLIonInputElement>();
  const zoomPathRef = React.createRef<HTMLIonInputElement>();
  const discordPathRef = React.createRef<HTMLIonInputElement>();
  const langRef = React.createRef<HTMLIonInputElement>();
  const startsRef = React.createRef<HTMLIonInputElement>();
  const timeStartsRef = React.createRef<HTMLIonInputElement>();
  const timeEndshRef = React.createRef<HTMLIonInputElement>();

  const [loading, setLoading] = useState<boolean>(false);
  const [batch, setBatch] = useState<BatchResponse_ShowBatch>();

  const bid = new URLSearchParams(location.search)?.get('bid');

  const onUpdateHandler = async () => {
    if (!bid) return;

    const days = daysRef.current?.value?.toString();
    const name = nameRef.current?.value?.toString();
    const mentor = mentorRef.current?.value?.toString();
    const purpose = purposeRef.current?.value?.toString();
    const username = usernameRef.current?.value?.toString();
    const password = passwordRef.current?.value?.toString();
    const rtmpPath = rtmpPathRef.current?.value?.toString();
    const meetPath = meetPathRef.current?.value?.toString();
    const zoomPath = zoomPathRef.current?.value?.toString();
    const discordPath = discordPathRef.current?.value?.toString();
    const lang = langRef.current?.value?.toString();
    const starts = startsRef.current?.value?.toString();
    const timeEnds = timeEndshRef.current?.value?.toString();
    const timeStarts = timeStartsRef.current?.value?.toString();

    const data: BatchPayload_UpdateBatch = { batch: bid };

    if (name) data.batchName = name;
    if (mentor) data.batchMentorName = mentor;
    if (purpose) data.batchPurpose = purpose;
    if (username) data.username = username;
    if (password) data.password = password;
    if (rtmpPath) data.meta = { ...data.meta, rtmpPath, meetPath, zoomPath, discordPath };
    if (lang) data.meta = { ...data.meta, lang };
    if (days && timeStarts && timeEnds) {
      data.schedule = {
        starts: starts || "",
        days: days ? days.split(", ").map(e => e.trim()) : [],
        time: { ends: timeEnds, starts: timeStarts }
      }
    }

    const { error, value } = Joi.object(BatchPayloadJoi_UpdateBatch).validate(data);
    if (!error) {
      setLoading(true);
      patchUpdateBatch(value, (response) => {
        dispatch(updateBatch({ batch: response.data.batch.id, data: response.data.batch }));
        setLoading(false);
        history.goBack();
      }, error => {
        errorToast(error);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!bid) return;
    setLoading(true);
    getShowBatch({ batch: bid }, res => {
      setBatch(res.data);
      setLoading(false);
    }, error => {
      errorToast(error);
      setLoading(false);
      history.goBack();
    });
  }, []);

  useEffect(() => {
    if (!batch) return;
    if (timeStartsRef.current) timeStartsRef.current.value = batch.batch.schedule.time.starts;
    if (timeEndshRef.current) timeEndshRef.current.value = batch.batch.schedule.time.ends;
    if (rtmpPathRef.current) rtmpPathRef.current.value = batch.batch.meta.rtmpPath;
    if (meetPathRef.current) meetPathRef.current.value = batch.batch.meta.meetPath;
    if (zoomPathRef.current) zoomPathRef.current.value = batch.batch.meta.zoomPath;
    if (discordPathRef.current) discordPathRef.current.value = batch.batch.meta.discordPath;
    if (langRef.current) langRef.current.value = batch.batch.meta.lang;
    if (startsRef.current) startsRef.current.value = batch.batch.schedule.starts;
    if (usernameRef.current) usernameRef.current.value = batch.batch.username;
    if (purposeRef.current) purposeRef.current.value = batch.batch.batchPurpose;
    if (mentorRef.current) mentorRef.current.value = batch.batch.batchMentorName;
    if (daysRef.current) daysRef.current.value = batch.batch.schedule.days.join(", ");
    if (nameRef.current) nameRef.current.value = batch.batch.batchName;
  }, [batch]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <IonPage>
      <Header backBtn="/" />
      <IonContent fullscreen>
        <IonGrid className="h-full overflow-hidden">
          <IonRow className="h-full overflow-scroll">
            <IonCol size="12" sizeSm="8" sizeMd="6" sizeLg="5" sizeXl="4">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonInput ref={nameRef} fill="solid" placeholder="Name" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={mentorRef} fill="solid" placeholder="Mentor" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={purposeRef} fill="solid" placeholder="Purpose" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={usernameRef} fill="solid" placeholder="Username" />
                  </IonCol>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={passwordRef} fill="solid" placeholder="Password" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={rtmpPathRef} fill="solid" placeholder="https://72hoor.in/java/hls/java.m3u8" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={meetPathRef} fill="solid" placeholder="https://meet.google.com/hia-gwcv-aew" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={zoomPathRef} fill="solid" placeholder="https://us05web.zoom.us/j/82218660260?pwd=xyz.1" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={discordPathRef} fill="solid" placeholder="https://discord.gg/XbRFKdqg" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={langRef} fill="solid" placeholder="Lang" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={daysRef} fill="solid" placeholder="Monday, Wednesday" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={startsRef} fill="solid" placeholder="July 1, 2024" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={timeStartsRef} fill="solid" placeholder="06:30 PM" />
                  </IonCol>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={timeEndshRef} fill="solid" placeholder="07:00 PM" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton onClick={onUpdateHandler} disabled={!isAdmin()} expand="full">Update</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UpdateBatch;
