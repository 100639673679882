import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counterSlice";
import courseState from "./reducers/courseState";
import batchState from "./reducers/batchState";
import userState from "./reducers/userState";
import uiState from "./reducers/uiState";

export default configureStore({
  reducer: {
    uiState: uiState,
    userState: userState,
    batchState: batchState,
    counter: counterReducer,
    courseState: courseState,
  },
});
