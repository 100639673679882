import { deleteDeleteBatch, errorToast, getCourses, getShowBatches, patchUpdateLiveStatusBatch, postBatchAssignUserToBatch, postDeleteUserFromBatch } from "../apis";
import { cogOutline, logoDiscord, peopleCircleOutline, personAddOutline, personRemoveOutline, statsChartOutline, trashOutline, videocamOutline } from "ionicons/icons";
import { deleteBatch, saveBatches, updateBatch, updateLiveStatus } from "../redux/reducers/batchState";
import SingleInputAlertIPC, { ISingleInputAlertIPC } from "../components/SingleInputAlert";
import { CoursesResponse_ShowCourses } from "@com.xcodeclazz/monolithic-common";
import WarningAlert, { IWarningAlert } from "../components/WarningAlert";
import { saveCourses } from "../redux/reducers/courseState";
import { useDispatch, useSelector } from "react-redux";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import Header from "../components/common/Header";
import { socketUrl } from "../apis/routes";
import React, { useEffect } from "react";
import _ from "lodash";
import {
  IonRow,
  IonCol,
  IonPage,
  IonGrid,
  IonCard,
  IonText,
  IonIcon,
  IonToggle,
  IonButton,
  IonContent,
  IonButtons,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonRippleEffect,
} from "@ionic/react";

const Dashboard: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const batches: BatchAttrs[] = useSelector((state: any) => state.batchState.batches);
  const courses_state: CoursesResponse_ShowCourses = useSelector((state: any) => state.courseState.courses);

  const deleteWarningAlert = React.createRef<IWarningAlert>();
  const addUserInputRef = React.createRef<ISingleInputAlertIPC>();
  const delUserInputRef = React.createRef<ISingleInputAlertIPC>();

  useEffect(() => {
    if (courses_state?.courses?.length == 0) getCourses(res => dispatch(saveCourses(res?.data)), errorToast);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    getShowBatches((response) => {
      dispatch(saveBatches(response.data))
    }, (error) => {
      console.log(error);
      errorToast(error);
    });
  }, []);

  const toggleLiveStatus = (batch: string, isLive: boolean) => {
    patchUpdateLiveStatusBatch({ batch, flag: !isLive }, (response) => {
      dispatch(updateLiveStatus({ batch, isLive: !isLive }));
    }, (error) => {
      console.log(error);
      errorToast(error);
    });
  };

  const deleteBatchHandler = (batch: any) => {
    deleteDeleteBatch({ batch: batch.data.id }, (response) => {
      dispatch(deleteBatch({ batch: batch.data.id, data: batch.data }));
    }, (error) => {
      console.log(error);
      errorToast(error);
    });
  };

  const assignUserToBatch = (id: string, batch: string, name: string) => {
    postBatchAssignUserToBatch({ id, batch, name }, (response) => {
      dispatch(updateBatch({ batch: response.data.batch.id, data: response.data.batch }));
    }, (error) => {
      console.log(error);
      errorToast(error);
    });
  };

  const deleteUserFromBatch = (id: string, batch: string) => {
    postDeleteUserFromBatch({ id, batch }, (response) => {
      dispatch(updateBatch({ batch: response.data.batch.id, data: response.data.batch }));
    }, (error) => {
      console.log(error);
      errorToast(error);
    });
  };

  return (
    <IonPage>
      <Header createBatch="/create-batch" backBtn="/" />
      <IonContent fullscreen>
        <IonGrid className="overflow-hidden">
          <IonRow>
            <IonCol className="p-4 flex flex-row items-center space-x-2">
              <IonText className="text-4xl font-bold">Courses</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            {_.map(courses_state?.courses, (el, idx) => (
              <IonCol key={idx} size="12" sizeSm="6" sizeMd="5" sizeXl="4">
                <IonCard className="ion-activatable" routerLink={`/create-batch?cid=${el?.id}&title=${el?.title}`}>
                  <IonRippleEffect></IonRippleEffect>
                  <IonCardHeader>
                    <IonText color={"primary"}>&#8377; {el.price}/-</IonText>
                    <IonCardTitle>{el.title}</IonCardTitle>
                    <IonCardSubtitle>{el.subtitle}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>{el.brief_description}</p>
                    <br />
                    <strong>{el?.duration?.title} - TTS - 2:30 PM</strong>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonCol className="p-4 flex flex-row items-center space-x-2">
              <IonText className="text-4xl font-bold">Batches</IonText>
            </IonCol>
          </IonRow>
          <IonRow className="overflow-scroll">
            {
              _.map(batches, (el, idx) => (
                <IonCol key={idx} size="12" sizeSm="12" sizeMd="7" sizeLg="5" sizeXl="4">
                  <IonCard>
                    <IonCardHeader>
                      <IonToggle checked={el.isLive} onIonChange={e => toggleLiveStatus(el.id, el.isLive!)}>{el.batchMentorName}</IonToggle>
                      <IonCardTitle className="font-bold">{el.batchName}</IonCardTitle>
                      <IonCardSubtitle>{el.batchPurpose}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <hr />
                      <IonGrid className="ion-no-padding">
                        <IonRow>
                          <IonCol size="3">Schedule</IonCol>
                          <IonCol size="9">{el.schedule.days.map(e => e.substring(0, 3)).join(", ")} ({el.schedule.time.starts} - {el.schedule.time.ends})</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">rtmp</IonCol>
                          <IonCol size="9">{el.meta.rtmpPath}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">meet</IonCol>
                          <IonCol size="9">{el.meta.meetPath}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">zoom</IonCol>
                          <IonCol size="9">{el.meta.zoomPath}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">discord</IonCol>
                          <IonCol size="9">{el.meta.discordPath}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">lang</IonCol>
                          <IonCol size="9">{el.meta.lang}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">Username</IonCol>
                          <IonCol size="9">{el.username}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="3">Strength</IonCol>
                          <IonCol size="9">{el.users?.length}</IonCol>
                        </IonRow>
                      </IonGrid>
                      <hr />
                      <IonButtons className="flex flex-wrap md:space-x-2">
                        <IonButton fill="clear" color="danger" onClick={e => delUserInputRef.current?.open(el?.id)}>
                          <IonIcon slot="end" icon={personRemoveOutline} />
                        </IonButton>
                        <IonButton fill="clear" color="success" onClick={e => addUserInputRef.current?.open(el?.id)}>
                          <IonIcon slot="end" icon={personAddOutline} />
                        </IonButton>
                        <IonButton fill="clear" color="danger" onClick={e => deleteWarningAlert?.current?.open(el, "Delete", "Delete Batch?")}>
                          <IonIcon slot="end" icon={trashOutline} />
                        </IonButton>
                        <IonButton fill="clear" color="success" routerDirection="none" routerLink={`/update-batch?bid=${el.id}`}>
                          <IonIcon slot="end" icon={cogOutline} />
                        </IonButton>
                        <IonButton fill="clear" color="medium" href={el.meta.discordPath} target="_blank">
                          <IonIcon slot="end" icon={logoDiscord} />
                        </IonButton>
                        <IonButton fill="clear" color="medium" href={el.meta.meetPath} target="_blank">
                          <IonIcon slot="end" icon={peopleCircleOutline} />
                        </IonButton>
                        <IonButton fill="clear" color="medium" href={el.meta.zoomPath} target="_blank">
                          <IonIcon slot="end" icon={videocamOutline} />
                        </IonButton>
                        <IonButton fill="clear" color="medium" href={`${socketUrl}lobby?roomId=${el.id}&name=admin`} target="_blank">
                          <IonIcon slot="end" icon={statsChartOutline} />
                        </IonButton>
                      </IonButtons>
                      <SingleInputAlertIPC onCreate={e => assignUserToBatch(e.id, e.bid, e.name)} ref={addUserInputRef} header="Add User" />
                      <SingleInputAlertIPC onCreate={e => deleteUserFromBatch(e.id, e.bid)} ref={delUserInputRef} header="Delete User" />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))
            }
          </IonRow>
        </IonGrid>
        <WarningAlert ref={deleteWarningAlert} onOkClicked={el => deleteBatchHandler(el)} onCancelClicked={e => {}} />
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
