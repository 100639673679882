import _ from 'lodash';
import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { moonOutline } from "ionicons/icons";
import AuthState from "../../utils/common/auth-state";
import { setTheme } from '../../redux/reducers/uiState';
import { isDark, toggleDark } from '../../utils/common/helper';
import { PREFERENCE_KEYS, setPreference } from '../../utils/common/cache';
import {
  IonIcon,
  IonTitle,
  IonButton,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";

const Header: React.FC<{
  onCreate?: () => void;
  onUpdate?: () => void;

  createBatch?: string;
  dashboard?: string;

  settingIcon?: boolean;
  logoutIcon?: boolean;
  backBtn?: string;
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = new AuthState();

  return (
    <IonHeader>
      <IonToolbar>
        {props.backBtn && <IonButtons slot="start" className='md:hidden'>
          <IonBackButton defaultHref={props.backBtn}></IonBackButton>
        </IonButtons>}
        <IonTitle>
          <strong className="text-yellow-500">x</strong>
          <strong className="cursor-pointer" onClick={(e) => history.push("/")}>CodeClazz Batch</strong>
        </IonTitle>
        <IonButtons slot="end">
          {props.onCreate && <IonButton onClick={props.onCreate}>Create</IonButton>}
          {props.createBatch && <IonButton routerDirection="none" routerLink={props.createBatch}>Create Batch</IonButton>}
          {authState.validateUser() ? 
            (
              <>
                {props.dashboard && (<IonButton routerDirection="none" routerLink={props.dashboard}>Dashboard</IonButton>)}
              </>
            ) : (
              <>
                {/* <IonButton routerDirection="none" routerLink="/login">Login</IonButton> */}
                {/* <IonButton routerDirection="none" routerLink="/register">Register</IonButton> */}
              </>
          )}
          {<IonButton title="theme" onClick={e => { toggleDark(); setPreference(PREFERENCE_KEYS.DARK_MODE, `${isDark()}`); dispatch(setTheme({ theme: `${isDark()}` })) }}>
            <IonIcon slot="icon-only" icon={moonOutline} />
          </IonButton>}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
