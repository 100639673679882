import _ from 'lodash';
import { routes } from './routes';
import Capacitor from '../utils/Capacitor';
import AuthState from '../utils/common/auth-state';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { BatchPayload_AssignUserToBatch, BatchPayload_CreateBatch, BatchPayload_DeleteBatch, BatchPayload_DeleteUserFromBatch, BatchPayload_Login, BatchPayload_ShowBatch, BatchPayload_ShowBatchByCourse, BatchPayload_ShowBatchStrength, BatchPayload_ShowIsAllowed, BatchPayload_UpdateBatch, BatchPayload_UpdateLiveStatusOfBatch } from "@com.xcodeclazz/batch";

const authState = new AuthState();

const handle = (response: HttpResponse, cb: (response: HttpResponse) => void) => {
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode <= 399) return cb(response);
    else if (statusCode >= 400 && statusCode <= 499) throw { response };
    else if (statusCode >= 500 && statusCode <= 599) throw { response };
    throw { response };
};

export const postBatchAssignUserToBatch = _.debounce(( data: BatchPayload_AssignUserToBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_BATCH_ASSIGN_USER_TO_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCreateBatch = _.debounce(( data: BatchPayload_CreateBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_CREATE_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const deleteDeleteBatch = _.debounce(( data: BatchPayload_DeleteBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.delete({
        url: routes.DELETE_DELETE_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postDeleteUserFromBatch = _.debounce(( data: BatchPayload_DeleteUserFromBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_DELETE_USER_FROM_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postLoginBatch = _.debounce(( data: BatchPayload_Login, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_LOGIN_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowBatchByCourse = _.debounce(( data: BatchPayload_ShowBatchByCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_BATCH_BY_COURSE.replace(":course", data.course),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowBatchStrength = _.debounce(( data: BatchPayload_ShowBatchStrength, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_BATCH_STRENGTH.replace(":batch", data.batch),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowBatch = _.debounce(( data: BatchPayload_ShowBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_BATCH.replace(":batch", data.batch),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowBatches = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_BATCHES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postShowIsAllowed = _.debounce(( data: BatchPayload_ShowIsAllowed, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SHOW_IS_ALLOWED,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowLiveBatches = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_LIVE_BATCHES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowMyBatches = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_MY_BATCHES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getShowNonCourseBatches = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SHOW_NON_COURSE_BATCHES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const patchUpdateBatch = _.debounce(( data: BatchPayload_UpdateBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.patch({
        url: routes.PATCH_UPDATE_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const patchUpdateLiveStatusBatch = _.debounce(( data: BatchPayload_UpdateLiveStatusOfBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.patch({
        url: routes.PATCH_UPDATE_LIVE_STATUS_BATCH,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getCourses = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_COURSES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getRandomProgrammingJoke = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: "https://official-joke-api.appspot.com/jokes/programming/random",
    }).then(e => handle(e, cb)).catch(err);
});

export const errorToast = _.debounce((error: any) => {
    // @ts-ignore
    const message = _.first(error.response?.data.errors)?.message;
    if (message) Capacitor.toast(message, "long");
});

const commonHeader = () => {
    return {
        'Content-Type': 'application/json',
        'base64': authState.getToken() || '',
    };
}