import { BatchPayloadJoi_CreateBatch, BatchPayload_CreateBatch } from "@com.xcodeclazz/batch";
import { getQueryParam, isAdmin } from "../utils/common/helper";
import { addBatch } from "../redux/reducers/batchState";
import { errorToast, postCreateBatch } from "../apis";
import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Joi from "joi";
import {
  IonCol,
  IonRow,
  IonPage,
  IonGrid,
  IonInput,
  IonButton,
  IonContent,
} from "@ionic/react";

const CreateBatch: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cid] = useState(getQueryParam()["cid"]);
  const [title] = useState(getQueryParam()["title"]);

  const daysRef = React.createRef<HTMLIonInputElement>();
  const nameRef = React.createRef<HTMLIonInputElement>();
  const mentorRef = React.createRef<HTMLIonInputElement>();
  const purposeRef = React.createRef<HTMLIonInputElement>();
  const usernameRef = React.createRef<HTMLIonInputElement>();
  const passwordRef = React.createRef<HTMLIonInputElement>();
  const courseIdRef = React.createRef<HTMLIonInputElement>();
  const rtmpPathRef = React.createRef<HTMLIonInputElement>();
  const meetPathRef = React.createRef<HTMLIonInputElement>();
  const zoomPathRef = React.createRef<HTMLIonInputElement>();
  const discordPathRef = React.createRef<HTMLIonInputElement>();
  const langRef = React.createRef<HTMLIonInputElement>();
  const startsRef = React.createRef<HTMLIonInputElement>();
  const timeStartsRef = React.createRef<HTMLIonInputElement>();
  const timeEndshRef = React.createRef<HTMLIonInputElement>();

  const [loading, setLoading] = useState<boolean>(false);

  const onCreateHandler = async () => {
    const days = daysRef.current?.value?.toString();
    const name = nameRef.current?.value?.toString();
    const mentor = mentorRef.current?.value?.toString();
    const purpose = purposeRef.current?.value?.toString();
    const course = courseIdRef.current?.value?.toString();
    const username = usernameRef.current?.value?.toString();
    const password = passwordRef.current?.value?.toString();
    const rtmpPath = rtmpPathRef.current?.value?.toString();
    const meetPath = meetPathRef.current?.value?.toString();
    const zoomPath = zoomPathRef.current?.value?.toString();
    const discordPath = discordPathRef.current?.value?.toString();
    const lang = langRef.current?.value?.toString();
    const starts = startsRef.current?.value?.toString();
    const timeEnds = timeEndshRef.current?.value?.toString();
    const timeStarts = timeStartsRef.current?.value?.toString();
 
    if (days == "" || name == "" || mentor == "" || purpose == "" || username == "" || password == "" || rtmpPath == "" || lang == "" || timeEnds == "" || timeStarts == "") return;

    const data: BatchPayload_CreateBatch = {
      username: username || "",
      password: password || "",
      batchName: name || "",
      meta: { lang: lang || "", rtmpPath: rtmpPath || "", meetPath: meetPath || "", discordPath: discordPath || "", zoomPath: zoomPath || "" },
      batchPurpose: purpose || "",
      batchMentorName: mentor || "",
      schedule: { 
        starts: starts || "",
        days: (days || "").split(',').map(e => e.trim()), 
        time: { starts: timeStarts || "", ends: timeEnds || "" }
      },
    };

    if (course) _.assign(data, { course });

    const { error, value } = Joi.object(BatchPayloadJoi_CreateBatch).validate(data);
    if (!error) {
      setLoading(true);
      postCreateBatch(value, (response) => {
        dispatch(addBatch(response.data.batch));
        setLoading(false);
        history.goBack();
      }, error => {
        errorToast(error);
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    if (cid && courseIdRef.current) { 
      courseIdRef.current.value = cid;
      courseIdRef.current.disabled = true;
    }
    if (title && nameRef.current) {
      nameRef.current.value = title;
      nameRef.current.disabled = true;
    }
  }, [cid, title]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <IonPage>
      <Header backBtn="/" />
      <IonContent fullscreen>
        <IonGrid className="h-full overflow-hidden">
          <IonRow className="h-full overflow-scroll">
            <IonCol size="12" sizeSm="8" sizeMd="6" sizeLg="5" sizeXl="4">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonInput ref={nameRef} fill="solid" placeholder="Name" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={courseIdRef} fill="solid" placeholder="Course Id" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={mentorRef} fill="solid" placeholder="Mentor" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={purposeRef} fill="solid" placeholder="Purpose" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={rtmpPathRef} fill="solid" placeholder="https://72hoor.in/java/hls/java.m3u8" value={"https://72hoor.in/java/hls/java.m3u8"} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={meetPathRef} fill="solid" placeholder="https://meet.google.com/hia-gwcv-aew" value={"https://meet.google.com/hia-gwcv-aew"} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={zoomPathRef} fill="solid" placeholder="https://us05web.zoom.us/j/82218660260?pwd=xyz.1" value={"https://us05web.zoom.us/j/82218660260?pwd=xyz.1"} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={discordPathRef} fill="solid" placeholder="https://discord.gg/XbRFKdqg" value={"https://discord.gg/XbRFKdqg"} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={usernameRef} fill="solid" placeholder="Username" />
                  </IonCol>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={passwordRef} fill="solid" placeholder="Password" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={langRef} fill="solid" placeholder="Lang" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={daysRef} fill="solid" placeholder="Monday, Wednesday" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={startsRef} fill="solid" placeholder="July 1, 2024" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={timeStartsRef} fill="solid" placeholder="06:30 PM" />
                  </IonCol>
                  <IonCol size="12" sizeSm="6">
                    <IonInput ref={timeEndshRef} fill="solid" placeholder="07:00 PM" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton onClick={onCreateHandler} disabled={!isAdmin()} expand="full">Create</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateBatch;
