export const socketUrl = process.env.REACT_APP_SERVER_SOCKET_URL || 'http://localhost:4009/';
const backend = process.env.REACT_APP_SERVER_BACKEND_URL || 'http://localhost:8090/'; // proxy
const monolithic = process.env.REACT_APP_SERVER_MONOLITHIC_URL || 'http://localhost:8087/';

export const routes = {
  POST_BATCH_ASSIGN_USER_TO_BATCH: `${backend}api/batch/user/assign`,
  POST_CREATE_BATCH: `${backend}api/batch`,
  DELETE_DELETE_BATCH: `${backend}api/batch`,
  POST_DELETE_USER_FROM_BATCH: `${backend}api/batch/user/delete`,
  POST_LOGIN_BATCH: `${backend}api/batch/login`,
  GET_SHOW_BATCH_BY_COURSE: `${backend}api/batch/:course`,
  GET_SHOW_BATCH_STRENGTH: `${backend}api/batch/strength/:batch`,
  GET_SHOW_BATCH: `${backend}api/batch/:batch`,
  GET_SHOW_BATCHES: `${backend}api/batch/batches/all`,
  POST_SHOW_IS_ALLOWED: `${backend}api/batch/isallowed`,
  GET_SHOW_LIVE_BATCHES: `${backend}api/batch/live`,
  GET_SHOW_MY_BATCHES: `${backend}api/batch/batches/my`,
  GET_SHOW_NON_COURSE_BATCHES: `${backend}api/batch/noncourse`,
  PATCH_UPDATE_BATCH: `${backend}api/batch`,
  PATCH_UPDATE_LIVE_STATUS_BATCH: `${backend}api/batch/setlive`,

  GET_COURSES: `${monolithic}api/courses`,
};