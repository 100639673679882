import { forwardRef, useImperativeHandle, useState } from "react";
import { IonAlert } from "@ionic/react";

export interface ISingleInputAlertIPC {
  open(id?: string): void;
  close(id?: string): void;
}

const SingleInputAlertIPC = forwardRef<
  ISingleInputAlertIPC,
  {
    onCreate: (o: { name: string, id: string, bid: string }) => void;
    header: string;
  }
>((props, ref) => {
  const [opened, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string|undefined>(undefined);

  useImperativeHandle(ref, () => ({
    open: open,
    close: close,
  }));

  const open = (id?: string) => {
    setId(id);
    setOpen(true);
  }
  const close = () => {
    setId(undefined);
    setOpen(false);
  }

  return (
    <IonAlert
      isOpen={opened}
      onDidDismiss={(e) => {
        if (e.detail.data) props.onCreate({ ... e.detail.data.values, bid: id });
        close();
      }}
      header={props.header}
      buttons={["Ok"]}
      inputs={[
        {
          name: "name",
          type: "text",
          placeholder: "Enter Name",
        },
        {
          name: "id",
          type: "text",
          placeholder: "Enter Id",
        },
      ]}
    />
  );
});

export default SingleInputAlertIPC;
